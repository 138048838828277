import React from 'react';
import { PartnersWidget } from './PartnersWidget';

const RedirectPage: React.FC = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-black p-4 text-white">
      <div className="max-w-2xl mx-auto text-center">
        <img
          src="./fcml.png"
          alt="FCML"
          className="mb-6 max-w-[150px] h-auto mx-auto"
        />
        <h1 className="text-2xl font-bold mb-4">Уважаемый посетитель!</h1>
        <p className="mb-6">
          Если вы пришли на эту страницу в поисках официального сайта вашего любимого футбольного клуба <strong>"Металлург Липецк"</strong>, то перейти к нему можно по этой кнопке:
        </p>
        <a
          href="https://fcmetallurg-lp.ru"
          target="_self"
          rel="noopener noreferrer"
          className="bg-red-600 text-white font-semibold py-2 px-4 rounded mb-6 inline-block"
        >
          Официальный сайт ФК Металлург Липецк
        </a>
        <p className="mb-6">
          Если вы пришли в поисках неофициальной страницы ФК Металлург, располагавшейся здесь ранее, то вы можете перейти к ней по этой кнопке:
        </p>
        <a
          href="https://dev.striped.online/fcml"
          target="_self"
          rel="noopener noreferrer"
          className="bg-red-600 text-white font-semibold py-2 px-4 rounded mb-6 inline-block"
        >
          Неофициальная страница ФК Металлург
        </a>
        <p>
          Эта страница являлась частью нашего проекта, который вскоре переедет сюда. А страницу ФК Металлург мы постараемся и дальше поддерживать в актуальном состоянии.
        </p>
        <p className="mt-6 font-semibold">Спасибо за внимание!</p>
        <PartnersWidget />
      </div>
    </div>
  );
};

export default RedirectPage;
