import { Striped } from "../layout/StripedLogo";
import { Container, PartnersGroup } from "./styled";

interface PartnersWidgetProps { }

export const PartnersWidget: React.FC<PartnersWidgetProps> = () => {
    return (
        <Container>
            <PartnersGroup>
                <Striped />
            </PartnersGroup>
        </Container>
    );
}