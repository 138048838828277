import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 12px 12px 0;
    gap: 12px;
    width: 100%;
    @media (min-width: 450px) {
        padding: 24px 24px 0;
        min-width: 450px;
    }
`;

export const PartnersGroup = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 12px;
`;

export const PartnerLogo = styled.img`
    //width: 100px;
    height: 50px;
    margin: 0 12px;
    transition-duration: 0.35s;
    filter: grayscale(100%);
    opacity: 0.7;
    &:hover {
        transform: scale(1.05);
        filter: grayscale(0%);
        opacity: 1;
    }
    @media (max-width: 450px) {
        height: 30px;
    }
`;

export const PartnersGroupTitle = styled.div`
    font-size: 20px;
    //font-weight: bold;
    margin: 6px 0 6px;
    text-align: center;
`;